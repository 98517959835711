require("signature_pad")
var signaturePad;
var cancelButton = document.getElementById('clear-signature');
var submitButton = document.getElementById('submit-with-signature');
var submitStripeButton = document.getElementById('submit-stripe');

document.addEventListener('DOMContentLoaded', function(){
	console.log("signature ready...");
  const canvas = document.getElementById("JohnHancock-canvas");
  const hidden_field = document.getElementById("JohnHancock-hidden");

  if (canvas && hidden_field) {
    const parent_form = canvas.closest("form");
    signaturePad = new SignaturePad(canvas);

    parent_form.onsubmit = function() {
      hidden_field.value = signaturePad.toDataURL()
    }

    function resizeCanvas() {
      var data = signaturePad.toDataURL(); //Added
      var ratio =  Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d").scale(ratio, ratio);
      //signaturePad.clear();
      signaturePad.fromDataURL(data); //Added
    }    

    window.addEventListener("resize", resizeCanvas, true);
    
    resizeCanvas();
    signaturePad.clear();

  }
}, false)

if(cancelButton){
  cancelButton.addEventListener('click', function (event) {
    console.log("signature signaturePad.clear");
    signaturePad.clear();
  });
};

if(submitButton){
  submitButton.addEventListener('click', function (event) {
    checkEmptySignature();
  });
};

if(submitStripeButton){
submitStripeButton.addEventListener('click', function (event) {
  checkEmptySignature();
});
}




// function signatureClear() {
// 	console.log("signature signatureClear");
//   var canvas = document.getElementById("JohnHancock-canvas");
//   var context = canvas.getContext("2d");
//   context.clearRect(0, 0, canvas.width, canvas.height);
// }
// 
function checkEmptySignature(){
  console.log("check if signature exists");
  if (signaturePad.isEmpty()) {
    console.log("Empty!");
    alert("Signature can't be blank. Please sign signature box with your mouse or trackpad.");
    event.preventDefault();
    return false;
  }else{
    console.log("Nice sig bro!");
    //event.preventDefault();
    return true;
  }
}