// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import $ from 'jquery';
global.$ = jQuery;
window.Rails = Rails

require("@rails/ujs").start()
require("jquery-ui")
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("trix")
require("@rails/actiontext")

require("@nathanvda/cocoon")
require('@popperjs/core')
require("bootstrap")
//require("popper.js")
require("metismenu")
require("simplebar")
require("node-waves")
require("select2")
require("ion-rangeslider")
require("slick-carousel")
//require("magnific-popup")
//require('datatables.net-bs4')
//require("datatables.net-bs4/css/dataTables.bootstrap4.min.css")
require("@egjs/view360")
require("fabric")

global.ko = require("knockout");
//require("marzipano")
require("marzipano/dist/marzipano")
global.Marzipano = require('marzipano');
import "./colorEffects"

import { Tooltip, Popover } from "bootstrap"

import "intro.js"
import "chartkick/chart.js"
import 'select2'; // globally assign select2 fn to $ element
import 'select2/dist/css/select2.css';  // optional if you have css loader

import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'

//require("apexcharts")
window.ApexCharts = require("apexcharts") // expose to window

import {PanoViewer} from "@egjs/view360";

//require("packs/admin/pages/dashboard.init")
//import "./admin/best_in_place"
//import "./signature_pad"
import "./john_hancock"
import "./jquery.animateNumber"
//require("packs/panorama")

require("packs/app")



// jquery
//import $ from 'jquery';
//
//global.$ = $
//global.jQuery = $

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)